import { HeaderComponent } from 'components/header/component'
import { RasaContext } from 'context'
import { Dataset } from 'generic/dataset'
import * as GenericRedux from 'generic/genericRedux'
import React from 'react'
import { Language, LANGUAGE_CONFIGURATION_KEY, Languages } from '../constants'
import '../styles.css'
import * as Types from '../types'
import { LanguageItem } from './languageItem'

type LanguageSettingsProps = GenericRedux.AllComponentProps<Types.FullProfile>

interface LanguageSettingsState {
  communityId: string,
  communityIdentifier: string,
  languages: string[],
}

export class LanguagesSettings extends React.Component<LanguageSettingsProps, LanguageSettingsState> {
  public static contextType = RasaContext

  constructor(props: LanguageSettingsProps) {
    super(props)
    this.state = {
      communityId: '',
      communityIdentifier: '',
      languages: [],
    }
  }

  public componentDidMount = () => {
    this.context.user.init().then(({activeCommunity}) => {
      this.setState({
        communityId: activeCommunity.data.community_id,
        communityIdentifier: activeCommunity.communityId,
      }, () => {
        this.loadData(false)
      })
    })
  }
  public render() {
    return (
      <div className="settings-languages-wrapper">
        <HeaderComponent
          icon={'language'}
          title={'Settings'}
          subTitle={'Language preferences'}
          description={['Below you can toggle on any other languages you would like your newsletter to be compatible with.']}
        />
        <div className="content">
        {Languages.map((item: Language) =>
                <LanguageItem key={item.code} item={item}
                  isActive={this.isLanguageActive(item.code)}
                  onToggle={this.toggleLanguage} />)}
        </div>
      </div>
    )
  }
  private loadData = (forceRefresh: boolean) => {
    const params = [
      {param: 'key', value: LANGUAGE_CONFIGURATION_KEY},
      {param: 'forceRefresh', value: forceRefresh},
    ]
    return new Dataset().loadCommunityDataset('communityConfiguration', this.state.communityIdentifier, params)
      .then((configuration) => {
        if (configuration.length > 0) {
          this.setState({
            languages: configuration[0][0].value ? configuration[0][0].value.split(',') : [],
          })
        }
      })
  }
  private toggleLanguage = (lang, isActive) => {
    let newData = this.state.languages
    if (isActive) {
      newData.push(lang)
    } else {
      newData = this.state.languages.filter((x) => x !== lang)
    }
    return this.context.entityMetadata.getEntityObject('community_configuration')
            .then((entity) => {
              entity.setRecordId(this.state.communityIdentifier, null)
              entity.data.community_id = this.state.communityId
              entity.data.key = LANGUAGE_CONFIGURATION_KEY
              entity.data.value = newData.join(',')
              entity.data.is_active = true

              return entity.save().then(() => {
                this.loadData(true)
              })
            })
  }
  private isLanguageActive = (lang) => {
    return this.state.languages.filter((x) => x === lang).length > 0
  }
}
